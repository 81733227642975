@import '../../../Styles/settings';

.root {
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
  margin-bottom: 2px;
  position: relative;
}

.wrapper {
  background: white;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 8px 20px;
}

.title {
  width: 65%;
  height: 37px;
  margin-bottom: 15px;
}

.text {
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
}

.date {
  width: 100%;
  height: 10px;
  max-width: 120px;
  margin-left: auto;
}

.title,
.text,
.date {
  background: $color-skeleton-items;
}

@include media(424px) {
  .title {
    width: 45%;
  }
}

@media #{$md-up} {
  .title {
    height: 42px;
  }
  .text {
    width: 100%;
  }
}
