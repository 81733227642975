@import '../../Styles/settings';

@keyframes productListFilterLoading {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.7;
  }
}

.contentContainer {
  --filter-container-spacing: 0;

  @media #{$md-up} {
    --filter-container-spacing: 48px;
  }

  height: fit-content;
  order: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateY(
    120%
  ); // 120% because of mobile native bottom navigation bar
  transition: transform 0.3s $main-timing-function;
  z-index: map-get($zIndexes, 'modalNoHeader');
  margin-bottom: var(--filter-container-spacing);
}
.contentContainerOpen {
  transform: translateY(0);
}

.filterIcon {
  fill: #fff;
  width: 100%;
  height: auto;
}

.productListFilterLoading {
  background: $color-cta;
  border-radius: 100%;
  position: fixed;
  bottom: 20px;
  right: 50%;
  transform: translateX(50%);
  z-index: map-get($zIndexes, 'filterBtn');
  animation: productListFilterLoading 1s $main-timing-function infinite;
  width: 56px;
  height: 56px;
  .filterIcon {
    display: none;
    fill: $text-color;
  }
}

@media #{$md-up} {
  .filterBtnContainer {
    display: none;
  }

  .productListFilterLoading,
  .contentContainer {
    position: static;
    grid-column-end: span 3;
    order: 0;
    transform: none;
    z-index: auto;
    height: fit-content;
  }

  .productListFilterLoading {
    background: transparent;
    border-radius: 0;
    position: static;
    height: auto;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 100px;
    .filterIcon {
      display: block;
    }
  }

  .filterIcon {
    width: 100%;
    max-width: 100px;
    height: auto;
  }
}

.show-filter-btn {
  --show: inline-flex;

  @media #{$md-up} {
    --show: none;
  }

  background-color: $white;
  padding: 8px 16px;
  display: var(--show);
  font-size: 12px;
  color: $color-brand;
  @include HelveticaMedium;

  svg {
    margin-left: 4px;
  }
}
