@import '../../Styles/settings';

.background-theme {
  min-height: 420px;

  &--beige {
    background-color: $color-bona-homeowner;
  }

  &--gray {
    background-color: $near-white;
  }
}
