@import '../../Styles/settings';
@import '../../Styles/variables';

.load-more {
  --btn-width: 100%;

  @media #{$sm-up} {
    --btn-width: 264px;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;

  &__btn {
    width: var(--btn-width);
    margin-top: 16px;
    position: relative;

    button {
      border: 1px solid transparent;
    }
  }

  &__text {
    opacity: 1;
  }

  &__spinner {
    display: none;
    position: absolute;
    left: 47%;
    width: 25px;
    height: 25px;
    animation: rotate 2s linear infinite;

    &--path {
      stroke: $color-brand;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  &[data-loading='true'] {
    .load-more__spinner {
      display: initial;
    }

    .load-more__text {
      opacity: 0;
    }
  }
}

.progress-bar {
  --progress-border-radius: 4px;
  display: flex;
  flex-direction: column;
  filter: $bona-drop-shadow;

  label {
    text-align: center;
    margin-bottom: 8px;
    color: $near-black;
  }

  progress {
    border: none;
    width: 264px;
    height: 8px;
    background: $bona-dark-grey;
    border-radius: var(--progress-border-radius);
  }

  progress::-moz-progress-bar {
    background: $white;
    border-radius: var(--progress-border-radius);
  }

  progress::-webkit-progress-value {
    background: $bona-dark-grey;
    border-radius: var(--progress-border-radius);
  }

  progress::-webkit-progress-bar {
    background: $white;
    border-radius: var(--progress-border-radius);
  }
}

@keyframes btnBottom {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes btnTop {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes circle {
  0% {
    stroke-dashoffset: calc(3.14 * 2 * 100%);
  }
  25% {
    stroke-dashoffset: calc(3.14 * 1 * 100%);
  }
  50% {
    stroke-dashoffset: 0%;
  }
  75% {
    stroke-dashoffset: -calc(3.14 * 1 * 100%);
  }
  100% {
    stroke-dashoffset: -calc(3.14 * 2 * 100%);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
    stroke: $color-brand;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
