@import '../../Styles/settings';

.dropdown {
  --width: 140px;
  --header-padding: 8px 16px;
  --header-padding-onboarding-segment: 12px 16px;
  --header-font-size-onboarding-segment: 12px;
  --item-font-size-onboarding-segment: 12px;
  --list-margin-top-onboarding-segment: 14px;
  --list-width: 240px;
  --item-white-space: none;

  @media #{$md-up} {
    --width: 176px;
    --header-padding: 4px 16px;
    --header-font-size-onboarding-segment: 18px;
    --header-padding-onboarding-segment: 12px 16px;
    --item-font-size-onboarding-segment: 16px;
    --list-margin-top-onboarding-segment: 20px;
    --list-width: auto;
    --item-white-space: nowrap;
  }

  position: relative;
  z-index: 90;
  background-color: #fff;
  padding: var(--header-padding);
  cursor: pointer;
  width: var(--width);
  border-radius: 4px;
  filter: drop-shadow(0px 4px 20px rgba(95, 99, 104, 0.08));

  &--grow {
    width: auto;
  }

  &--onboardingSegment {
    padding: var(--header-padding-onboarding-segment);
  }

  svg {
    transition: transform 0.2s ease;
    width: 20px;
    height: 20px;
  }

  &--open svg {
    transform: translate3d(0, 0, 0) rotate(180deg);
  }

  &__header {
    display: inline-flex;
    align-items: center;
    @include HelveticaMedium;
    width: 100%;
    justify-content: space-between;
    user-select: none;
    color: $color-brand;
    &--grow {
      column-gap: 8px;
    }
  }

  &__header-label {
    text-overflow: ellipsis;
    flex-basis: 80%;
    white-space: nowrap;
    overflow: hidden;
    &--grow {
      text-overflow: unset;
      overflow: unset;
    }
    &--onboardingSegment {
      @include HelveticaMedium;
      font-size: var(--header-font-size-onboarding-segment);
    }
  }

  &__list {
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #fff;
    width: var(--width);
    padding-left: 0;
    list-style: none;

    &--grow {
      width: var(--list-width);
    }
    &--onboardingSegment {
      margin-top: var(--list-margin-top-onboarding-segment);
      border-radius: 8px;
    }
  }

  &__item {
    padding: 16px 16px;
    white-space: var(--item-white-space);

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      background-color: $lightest-blue;
    }

    &--onboardingSegment {
      border-radius: 8px;
      font-size: var(--item-font-size-onboarding-segment);
      color: $midnight-blue;
    }
  }
}
