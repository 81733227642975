@import '../../../Styles/settings';

.root {
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
  margin-bottom: 2px;
  position: relative;
}

.wrapper {
  background: white;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 8px 20px;
}

.title {
  width: 90%;
  height: 24px;
  margin-bottom: 15px;
}

.attribute {
  width: 55%;
  height: 20px;
  margin-bottom: 10px;
}

.img {
  width: 100%;
  height: 170px;
}

.title,
.attribute,
.btn,
.img {
  background: $color-skeleton-items;
}

.btn {
  display: none;
}

@media #{$md-up} {
  :global([data-style='cards-as-list']) {
    .wrapper {
      display: flex;
      align-items: center;
      height: 110px;
      padding: 0 30px;
    }
    .title {
      width: 25%;
      order: 1;
    }
    .attribute {
      width: 25%;
      margin-left: auto;
      order: 2;
    }

    .title,
    .attribute {
      margin-bottom: 0;
    }
    .img {
      order: 0;
      height: 72px;
      width: 52px;
      margin-right: 10px;
    }

    .btn {
      order: 3;
      margin-left: 100px;
      height: 37px;
      display: block;
      width: 114px;
    }
  }
}
