@import '../../Styles/settings';

.root {
  display: flex;
  justify-content: center;
}

.btnContainer {
  display: flex;
  & + * {
    margin-left: 30px;
  }

  & svg {
    stroke: $color-brand;
  }
}
.btn {
  @include textBtn;
  padding: 0 5px;
  text-align: center;
  background-color: $white;
  padding: 8px;
  margin-left: 8px;
  border-radius: 4px;
  filter: $bona-drop-shadow;
}

.btnSelected {
  font-weight: 500;

  & svg {
    stroke: $light-blue;
  }
}
.listOption {
  display: none;
}

.sortOrder {
  margin-left: 32px;
}

@media #{$md-up} {
  .root {
    justify-content: space-between;
  }

  .listOption {
    display: flex;
    margin-left: 32px;
  }
}
