@import '../../Styles/settings';
@import '../../Styles/variables';

.prev-page {
  --link-size: 12px;

  @media #{$md-up} {
    --link-size: 16px;
  }

  color: $color-brand;

  &--margin {
    margin-bottom: 24px;
  }

  a {
    text-decoration: none;
    font-size: var(--link-size);
    line-height: 16px;
    @include HelveticaMedium;
    color: currentColor;
  }

  svg {
    fill: currentColor;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    position: relative;
    top: 1px;
  }
}
