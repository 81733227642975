@import '../../../Styles/settings';

.root {
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
  margin-bottom: 2px;
  position: relative;
  min-height: 80px;
}

.wrapper {
  background: white;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 15px 10px 10px;
}

.title {
  width: 90%;
  height: 24px;
  margin-bottom: 15px;
}

.text {
  width: 55%;
  height: 20px;
  margin-bottom: 10px;
}

.title,
.text {
  background: $color-skeleton-items;
}

@include media(500px) {
  .wrapper {
    padding: 10px;
  }
}

@include media(700px) {
  .wrapper {
    padding: 10px 15px 10px;
  }
}
