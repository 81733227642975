@import '../../Styles/settings';

@keyframes animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  opacity: 0;
  animation: animation 0.3s $main-timing-function 0.7s forwards;
  display: flex;
  flex-wrap: wrap;
}

@include media(350px) {
  .root {
    @include styleComponent('style_id_list_productSkeleton') {
      width: 50%;
    }
  }
}

@include media(480px) {
  .root {
    @include styleComponent('style_id_list_documentSkeleton') {
      width: 50%;
    }
  }
}

@include media(800px) {
  .root {
    @include styleComponent('style_id_list_productSkeleton') {
      width: 33.3333%;
    }
  }
}

@media #{$md-up} {
  :global([data-style='cards-as-list']) {
    @include styleComponent('style_id_list_productSkeleton') {
      width: 100%;
    }
  }
}
