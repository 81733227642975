@import '../../../Styles/settings';

$defaultBaseColor: transparent;

@keyframes skeleton {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.line {
  height: 100%;
  width: 100%;
  background-color: $defaultBaseColor;
  background-image: linear-gradient(
    90deg,
    $defaultBaseColor,
    $color-primary-bg,
    $defaultBaseColor
  );
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  animation: skeleton 1.2s ease-in-out 0.7s infinite;
}

.secondary {
  background-image: linear-gradient(
    90deg,
    $defaultBaseColor,
    $color-secondary-bg,
    $defaultBaseColor
  );
}
