@import '../../Styles/settings';

.root {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.pageIntro {
  margin-top: 20px !important;
  border-top: 0.5px solid $tundora;
  padding-top: 20px;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: map-get($zIndexes, 'loaderInSearchAndCategoryPage');
  background: white;
  opacity: 0;
  transition: opacity 0.3s $main-timing-function;
  &[data-loading='true'] {
    opacity: 0.5;
  }
}

:global(.list-wrapper) {
  padding: 0 0 40px;
  width: 100%;
}

.listHeader {
  display: flex;
  grid-column-end: span 12;
  margin-bottom: 10px;
  flex-grow: 1;
}

@media #{$md-up} {
  .root {
    display: grid;
    column-gap: 24px;
    row-gap: 24px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  :global(.list-wrapper) {
    grid-column-end: span 9;
  }

  .pageIntro {
    display: none;
  }

  .listHeader {
  }
}

.list-header {
  --header-label-size: 12px;
  --header-margin: 24px 0 32px 0;
  color: $darkest-gray;

  @media #{$md-up} {
    --header-label-size: 16px;
    --header-margin: 32px 0 0 0;
  }

  display: flex;
  grid-column-end: span 12;
  margin: var(--header-margin);
  flex-grow: 1;
  justify-content: space-between;
  position: relative;

  &__block {
    display: flex;
    align-items: center;
    font-size: var(--header-label-size);
  }

  &__hidden {
    display: none;
  }

  &__total-hits {
    white-space: nowrap;
  }
}
